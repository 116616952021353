import { getCookie, setCookie } from "cookies-next";

const DEBUG_ENABLED_NAME = "viewport_debug";
const DEBUG_LEVEL_COOKIE = "viewport_debug_level";

enum DEBUG_LEVEL {
  SS = "ss",
  GA = "ga",
  JT = "jt",
  TRACKING = "tracking",
  ALL = "all",
}

const TRACKING_LEVEL = ["ss", "ga", "jt"];
const styles = [
  "background: #3F51B5",
  "color: #FFF",
  "padding: 2px",
  "border-radius: 5px;",
  "line-height: 16px",
].join(";");
const stylesError = [
  "background: #B53F51",
  "color: #FFF",
  "padding: 2px",
  "border-radius: 5px;",
  "line-height: 16px",
].join(";");
const stylesInfo = [
  "background: #3A8EDB",
  "color: #FFF",
  "padding: 2px",
  "border-radius: 5px;",
  "line-height: 16px",
].join(";");
const message = "VIEWPORT ";

export function log(...args: any) {
  if (shouldUseDebug(...args)) {
    console.log("%c %s", styles, message, ...args);
  }
}

export function logError(...args: any) {
  if (shouldUseDebug(...args)) {
    console.log("%c %s", stylesError, message, ...args);
  }
}

export function logInfo(...args: any) {
  if (shouldUseDebug(...args)) {
    console.log("%c %s", stylesInfo, message, ...args)
  }
}

export function enableConsoleLog(level: string) {
  setCookie(DEBUG_LEVEL_COOKIE, level);

  console.log(
    "%c %s",
    styles,
    message,
    "DEBUG level was set to >>> ",
    DEBUG_LEVEL[level.toUpperCase() as keyof typeof DEBUG_LEVEL],
  );
}

export function exposeConsoleLogMethod() {
  window.viewportLog = (param: string | boolean): void => {
    if (typeof param === "boolean") {
      setCookie(DEBUG_ENABLED_NAME, "false");
      return;
    }

    if (Object.keys(DEBUG_LEVEL)?.includes(param?.toUpperCase() || "")) {
      setCookie(DEBUG_ENABLED_NAME, "true");
      enableConsoleLog(
        DEBUG_LEVEL[param.toUpperCase() as keyof typeof DEBUG_LEVEL],
      );
    } else {
      setCookie(DEBUG_ENABLED_NAME, "false");
    }
  };
}

function shouldUseDebug(...args: any): boolean {
  const debugEnabled = getCookie(DEBUG_ENABLED_NAME) === "true";
  const currentDebugLevel = getCookie(DEBUG_LEVEL_COOKIE) || "";

  return debugEnabled &&
    (currentDebugLevel === DEBUG_LEVEL.ALL ||
      (args[2]?.destination.toLowerCase() === currentDebugLevel &&
        currentDebugLevel === DEBUG_LEVEL.GA) ||
      (args[2]?.destination.toLowerCase() === currentDebugLevel &&
        currentDebugLevel === DEBUG_LEVEL.SS) ||
      (args[2]?.destination.toLowerCase() === currentDebugLevel &&
        currentDebugLevel === DEBUG_LEVEL.JT) ||
      (TRACKING_LEVEL.includes(args[2]?.destination.toLowerCase()) &&
        currentDebugLevel === DEBUG_LEVEL.TRACKING))
}
