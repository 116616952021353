"use client";
import { useMemo } from "react";

import { useSearchParams } from "next/navigation";

import { getCookie, setCookie } from "cookies-next";
import { useExperiment, useGate } from "statsig-react";

import {
  STATSIG_COOKIE_PREFIX,
  STATSIG_DEFAULT_VALUE,
  STATSIG_IS_ACTIVE_EXPERIMENT_NAME,
  STATSIG_OVERRIDE_COOKIE_PREFIX,
} from "shared-lib/constants";
import { getUtmParams } from "shared-lib/helpers/cookieFunctions";
import { sendExperimentGtagEvent } from "shared-lib/helpers/gtagFunctions";

export function useCustomGate(key: string) {
  const params = useSearchParams();
  const overridePresent = !params ? false : params.has(key);
  const { value } = useGate(key);

  if (overridePresent) {
    return !params ? false : params.get(key) === "true";
  } else {
    return value;
  }
}

export function useCustomExperiment(key: string) {
  const overrideValue = getCookie(STATSIG_OVERRIDE_COOKIE_PREFIX + key);
  const { config } = useExperiment(key);

  if (Object.prototype.hasOwnProperty.call(config?.value, "value")) {
    saveSession(key);
  }

  if (overrideValue) {
    return overrideValue;
  } else {
    const expVal = (config.value.value ?? STATSIG_DEFAULT_VALUE) as string;
    setCookie(`${STATSIG_COOKIE_PREFIX}${key}`, expVal);
    sendExperimentGtagEvent(key, expVal);
    return expVal;
  }
}

export function getUtmParamsForStatsig() {
  const params = getUtmParams();

  return {
    utm_campaign: (params["utm_campaign"] as string) ?? "Unknown",
    utm_content: (params["utm_content"] as string) ?? "Unknown",
    utm_cvb: (params["utm_cvb"] as string) ?? "Unknown",
    utm_medium: (params["utm_medium"] as string) ?? "Unknown",
    utm_source: (params["utm_source"] as string) ?? "Unknown",
  };
}

export function useStatsigInitValues() {
  const values = useMemo(() => {
    const userID = getCookie("userID") as string;
    const sdkKey = process.env.NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY ?? "";
    return { sdkKey, userID };
  }, []);

  return values;
}

export function isActiveExperiment() {
  return !!sessionStorage.getItem(STATSIG_IS_ACTIVE_EXPERIMENT_NAME);
}

function saveSession(key: string) {
  sessionStorage.setItem(STATSIG_IS_ACTIVE_EXPERIMENT_NAME, key);
}
