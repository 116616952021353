export function ezoicDefinePlaceholders(listOfPlaceholders: number[]) {
  window.ezstandalone = window?.ezstandalone || {};
  window.ezstandalone.cmd = window?.ezstandalone?.cmd || [];
  window.ezstandalone.cmd.push(function () {
    window.ezstandalone.define(listOfPlaceholders);
  });
}

/* this will call for the ad code of the potential placeholders (e.x. 102, 103, and 104).
  In order to have the ads show, display must be called. */
export function ezoicCallForAds() {
  window.ezstandalone = window?.ezstandalone || {};
  window.ezstandalone.cmd = window?.ezstandalone?.cmd || [];
  window.ezstandalone.cmd.push(function () {
    window.ezstandalone.enable();
  });
}

export function ezoicDisplayAds() {
  window.ezstandalone = window?.ezstandalone || {};
  window.ezstandalone.cmd = window?.ezstandalone?.cmd || [];
  window.ezstandalone.cmd.push(function () {
    window.ezstandalone.display();
  });
}

export function ezoicDisplayMoreAds(listOfPlaceholders: number[]) {
  window.ezstandalone = window?.ezstandalone || {};
  window.ezstandalone.cmd = window?.ezstandalone?.cmd || [];
  window.ezstandalone.cmd.push(function () {
    window.ezstandalone.displayMore(listOfPlaceholders);
  });
}

/* If the new page contains a different set of placeholders from the previous page, ezstandalone.define()
  should be called with the new set of placeholders prior to calling refresh. */
export function ezoicRefreshAds() {
  window.ezstandalone = window?.ezstandalone || {};
  window.ezstandalone.cmd = window?.ezstandalone?.cmd || [];
  window.ezstandalone.cmd.push(function () {
    window.ezstandalone.refresh();
  });
}

export function ezoicDestroyAll() {
  window.ezstandalone = window?.ezstandalone || {};
  window.ezstandalone.cmd = window?.ezstandalone?.cmd || [];
  window.ezstandalone.cmd.push(function () {
    window.ezstandalone.destroyAll();
  });
}

export function ezoicDestroyPlaceholders(listOfPlaceholders: number[]) {
  window.ezstandalone = window?.ezstandalone || {};
  window.ezstandalone.cmd = window?.ezstandalone?.cmd || [];
  window.ezstandalone.cmd.push(function () {
    window.ezstandalone.destroyPlaceholders(listOfPlaceholders);
  });
}

export function ezoicShowAds(listOfPlaceholders: number[]) {
  window.ezstandalone = window?.ezstandalone || {};
  window.ezstandalone.cmd = window?.ezstandalone?.cmd || [];
  window.ezstandalone.cmd.push(function () {
    window.ezstandalone.showAds(listOfPlaceholders);
  });
}

export function ezoicSetIdentity(identities: {
  md5?: string;
  sha256?: string;
  sha1?: string;
}) {
  window.ezoicIdentity = window.ezoicIdentity || {};
  window.ezoicIdentity.queue = window.ezoicIdentity.queue || [];
  window.ezoicIdentity.queue.push(function () {
    window.ezoicIdentity.setIdentity(identities);
  });
}
